// import {useParams} from 'react-router-dom'
import {CityCard} from '../components/CityCard'

const ContactsCitiesList = ({citiesList = []}) => {

    delete citiesList.total;
    let result = Object.keys(citiesList).map((key) => [Number(key), citiesList[key]]);

    return <>
        {result.map(project => ( <CityCard key={project[1].id} {...project[1]}/> ))}
    </>
}

export {ContactsCitiesList}