import React, {useEffect, useState} from 'react'
import ReactHtmlParser from 'react-html-parser'

import AOS from 'aos';
import 'aos/dist/aos.css';

import {Form} from ".././components/Form"
import {getContacts} from '../api'

// import 'bootstrap/dist/css/bootstrap.css'
// import "../index.sass";
import "./assets/Contacts.sass"
import {ContactsCitiesList} from "../components/ContactsCitiesList";
import {Helmet} from "react-helmet";

const Contacts = () => {

    AOS.init()

    const [Contacts, setContacts] = useState([])
    const [citiesList, setCitiesList] = useState([])

    useEffect(() => {
        getContacts().then(data => {setContacts(data.object)})

        setCitiesList(Contacts.cityes)
    }, [Contacts])


    let SEO_DESCR, SEO_KEYWORDS, TITLE = "Контакты. fresco.agency — AI и веб-интегратор"

    if (Object.entries(Contacts).length > 0){
        if (Contacts.SEO_TITLE) {
            TITLE = Contacts.SEO_TITLE
            SEO_DESCR = Contacts.SEO_DESCR
            SEO_KEYWORDS = Contacts.SEO_KEYWORDS
        }
    }

    return(
        <>
            <Helmet>
                <title>{ TITLE }</title>
                <meta name="description" content={SEO_DESCR} />
                <meta name="keywords" content={SEO_KEYWORDS} />
            </Helmet>

            <div className="contacts_page">
                <div className="container">
                    <div className="row align-items-center flex-column-reverse flex-md-row">
                        <div className="col-md-6">
                            <div className="intro__map map">
                                <div className="map_block">
                                    <iframe title="Карта"
                                        src="https://yandex.ru/map-widget/v1/?um=constructor%3A13bdec86bed2e2a94f1271b9927849521db605a50566a133eec51be147190248&amp;source=constructor"
                                        width="590" height="430" frameBorder="0"></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 offset-md-1">
                            <h1>{Contacts.title}</h1>
                            <div className="contacts-list">
                                {ReactHtmlParser(Contacts.text)}
                            </div>
                            <div className="row">
                                <div className="col-12 col-xl-10">
                                    <div className="row contacts-cities">
                                        <ContactsCitiesList citiesList={citiesList}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section id="footer_form">
                <Form />
            </section>
        </>
    )
}

export {Contacts}