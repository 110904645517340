import {BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import {Header} from "./components/Header"
import {Footer} from "./components/Footer"
import {ScrollToTop} from "./components/ScrollToTop"

import {Home} from "./pages/Home"
import {Case} from "./pages/Case"
import {About} from "./pages/About"
import {Services} from "./pages/Services"
import {Contacts} from "./pages/Contacts"
import {Projects} from "./pages/Projects"
import {Page404} from "./pages/Page-404"

import logo from './images/logo-2024.svg';
import {useEffect} from "react";

// import Section from "./components/Section/Section";
// import SmoothScroll from "./components/SmoothScroll/SmoothScroll";

function App() {

    useEffect(() => {
        const AOS = require("aos");
        AOS.init({
            once: true,
        });
    }, [])

    return (
            <div className="bodyWrapper">
                <Router>
                    <ScrollToTop />
                    <Header logo={logo}/>
                    <main>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/home" component={Home} />
                            <Route path="/about" component={About} />
                            <Route path="/contacts" component={Contacts} />
                            <Route path="/projects" component={Projects} />
                            <Route path="/case/:id" component={Case} />
                            <Route path="/services" component={Services} />
                            <Route component={Page404}/>
                        </Switch>
                    </main>
                    <Footer/>
                </Router>
            </div>
    );
}

export default App;