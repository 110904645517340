import FooterClass from "./Footer.module.sass";
import { Brief, Policy, Presentation } from "../services/constants";

const Footer= () => {
    return(
        <>
            <footer className={FooterClass['footer']}>
                <div className={FooterClass['footer__inner']}>
                    <div className={FooterClass['brief_wrapper']}>
                        <a href={Brief} target="_blank" className={'brief'}>
                            <span className={'brief__desc'}>С чего начать работу?</span>
                            <span className={'brief__text'}>Заполните бриф</span>
                        </a>
                    </div>

                    <div className={FooterClass['contacts']}>
                        <span className={FooterClass['address']}>Россия, Томск, ул. Яковлева, 15,<br></br> офисы «Round», 205</span>
                        <a href="tel:+7 (495) 999-49-00" className={FooterClass['phone']}>+7 (495) 999-49-00</a>
                        <a href="mailto:hello@fresco.agency" className={FooterClass['mail']}>hello@fresco.agency</a>
                    </div>

                    <div className={FooterClass['privat_policy']}>
                        <a href={Presentation} target="_blank" className="italic_link">Скачать презентацию</a>
                        <a href={Policy} target="_blank" className="italic_link">Политика конфиденциальности</a>
                    </div>
                </div>
            </footer>
        </>
    )
}

export {Footer}