import React from "react";
import {SITE_URL} from '../api'

const CityCard = (props) => {

    const {
        image,
        name,
        tel
    } = props

    return (
        <>
            <div className="col-6">
                <img src={SITE_URL + image} alt=""/>
                <p><b>{name}</b></p>
                <p><a href={`tel:` + tel}>{tel}</a></p>
            </div>
        </>
    )
}

export {CityCard}