import React, { useState } from 'react';
import './Tabs.scss';
import './Tabs-media.scss';

const Tabs = ({ list, onChange }) => {
  const [activeTab, setActiveTab] = useState(0);
  const OnChangeCategory = (category, i) => () => {
    onChange(category);
    setActiveTab(i);
  };

  return (
    <div className="tabs">
      {list.map((item, i) => (
        <div
          className={`tabs__item${activeTab === i ? ' tabs__item_active' : ''}`}
          onClick={OnChangeCategory(item, i)}
          key={i}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
