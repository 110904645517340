export const ListCategoryCase = [
  'Все',
  'ЛОГИСТИКА',
  'ПРОИЗВОДСТВО',
  'МЕДИЦИНА',
  'НЕДВИЖИМОСТЬ',
  'E-com',
  'ОБРАЗОВАНИЕ',
  '...'
];
