export const listReviews = [
  {
    image: require("./img/dalli-logo-otzyv.svg").default,
    name: "Антон Литвинов",
    position: "Digital-маркетолог службы доставки «Dalli»",
    site: "dalli-service.com",
    text: `
    Благодарим компанию fresco.agency за высокое качество и оперативность оказанных услуг по разработке и технической поддержке сайта компании.<br/>
    Отмечаем, что разработчики чётко следовали поставленному техническому заданию, вносили необходимые правки в обозначенный срок и находили творческие решения для решения возникавших сложностей.<br/>
    Исходя из опыта работы с командой, компания Dalli готова рекомендовать fresco.agency как надёжного партнёра для выполнения работ в сфере веб-разработки и дизайна.
    `,
    source: "https://drive.google.com/file/d/1mGhhkEYMPNf_ND3uN7AEhw3RCCnf8XBP/view",
  },
  {
    image: require("./img/tomskbeer-logo-otzyv.svg").default,
    name: "Максим Курохтин",
    position: "Заместитель генерального директора по маркетингу ОАО «Томское пиво»",
    site: "tomskbeer.ru",
    text: `
    Мы обратились в компанию fresco.agency, чтобы обновить наш веб-сайт (tomskbeer.ru) - сделать его современным, более понятным для поставщиков и дистрибьюторов и отразить весь наш ассортимент. Также обновленный сайт должен был наглядно показать богатую историю предприятия, рассказать о социальной политике, реализуемой руководством, коротко, но емко продемонстрировать преимущества нашего подхода к пивоварению.<br/><br/>

    Результатом мы остались довольны на 100%. Сайт получился современным, лаконичным, удобным для потребителя. Через два года статистика по посетителям сайта улучшилась более чем в 2 раза. В процессе использования (администрирования) сайт также показал своё удобство и надежность. А fresco.agency не оставили нас и после разработки сайта - всегда на связи и всегда готовы помочь, проконсультировать и т.д.<br/><br/>

    Сотрудничество с командой fresco.agency было лёгким и приятным. Нам понравилось общее качество, оперативная реакция на возникающие вопросы, индивидуальный подход к задачам. Хотим выразить отдельную благодарность менеджеру проекта, Дмитрию, за его компетентность, инициативность и способность разъяснить технические детали проекта простым и понятным языком.<br/><br/>

    Выражаем благодарность компании fresco.agency за проделанную большую работу и рассчитываем на дальнейшее плодотворное сотрудничество!
    `,
    source: "https://drive.google.com/file/d/1tcVP1UbITcgBl3NrzHAWCnC5e4H0yp-C/view",
  },
  {
    image: require("./img/mtg-citilab-otzyv.svg").default,
    name: "Дмитрий Обрядов",
    position: "Руководитель проектов «Main Target Group»",
    site: "main-target.ru",
    text: `
    У нас возникла потребность в том, чтобы усилить нашу команду для создания новых посадочных страниц/лендингов на проекте citilab.ru («Ситилаб»). В процессе поиска исполнителя мы столкнулись со сложностью найти команду, которая смогла бы “под ключ” взять этот блок работы на себя и обеспечить выполнение проекта в крайне сжатые сроки. Доверились рекомендации коллег, пообщались с руководителем проекта и .. достигли отличных результатов)<br/><br/>

    Несмотря на отсутствие четкого брифа и технического задания, благодаря решительным действиям руководителя проекта и специалистов команды, проект удалось выполнить в срок с очень достойным уровнем качества!
    Получилось сделать информативные и удобные страницы, которые соответствуют целям и нашей концепции, и отражают все ключевые предложения и опции. Сейчас мы планируем продвижение лендингов для привлечения клиентов.<br/><br/>

    Благодарим компанию fresco.agency за скорость, профессионализм и желаем успехов в делах!
    `,
  },
  {
    image: require("./img/mercuriya-logo.svg").default,
    name: "Станислав Сова",
    position: "Директор фонда «Меркурия»",
    site: "mercuriya.ru",
    text: `Задача стояла нетривиальная - сделать функциональный дизайн нового сайта благотворительного фонда. Мы связались почти со всеми известными компаниями города Томска, и пообщавшись с руководителем fresco.agency, сразу нашли общий язык.<br/>
    Мы предоставили прототип и наши пожелания в ходе беседы. Само общение оставило очень приятное ощущение - тебя слышат, понимают и предлагают решения.<br/>
    Результат нам очень понравился, мы хотели через сайт показать нашу деятельность, сделать её более прозрачной и понятной, чтобы привлекать новых людей к нам в команду, в качестве волонтёров, партнёров и спонсоров. И я считаю у нас это получилось!<br/>
    Однозначно рекомендую эту компанию. Они полностью справились с задачей,  показав очень чуткое отношение к нашему проекту. Видно, что у команды есть ценности и они просто не могут делать как попало.`,
  },
  {
    image: require("./img/sreda-logo.svg").default,
    name: "Кирилл Мишин",
    position: "Генеральный директор ООО «СИСТЕМНЫЕ РЕШЕНИЯ»",
    site: "",
    text: `Узнали по рекомендации. Агентство выбирали долго, обращались и в именитые /получили огромный ценник ультрапафос и сроки полгода/, и к ноунейм /прогиб ниже плинтуса, любой каприз за наши деньги, но к сожалению без гарантий/, и даже к частным исполнителям /копеечные цены и специалисты вчера посмотревшие обучающие видео на ютубе (в перемотке) - я конечно немного утрирую, но суть та же. Остановились на компании fresco.agency из-за соотношения "цена-качество" и конкретики при обсуждении ТЗ /опять же рекомендация сыграла свою роль.<br/>

    Запомнилось полное отсутствие геммороя, четкое соответствие сроков и точность в исполнении наших задач.
    Впечатления строго положительные) Со своей стороны обещаем рекомендовать вас всем нашим партнерам и просто знакомым как Профессиональных Исполнителей. Соответственно оценка: 10 очень довольных клиентов из 10)<br/>
    Ассоциация -> Качество`,
  },
  {
    image: require("./img/logsis-logo.svg").default,
    name: "Денис Смелов",
    position: "Коммерческий директор «Logsis»",
    site: "logsis.ru",
    text: `На разработку нового сайта компании рассматривал 2 московских и пару региональных студий. И поскольку выбирал впервые, то было много личных переживаний и страхов, не было принципиального понимания как ведется проект и совпадет ли результат с ожиданиями вообще.<br/>

    При знакомстве и первом общении с компанией fresco.agency понравился общий подход к работе – все делают по-этапно и объясняют на пальцах что и за чем следует, таким образом, уже на начальном этапе у меня в голове сложилась картинка проекта и что следует ожидать в итоге.<br/>

    Сначала мы провели несколько скайп-сессий для брифинга (уточнили цели и требования), сразу сообщил, что есть строгое ограничение по сроку - сайт должен быть запущен к выставке Ecom Expo (через 2 месяца). Потом мне объяснили из каких этапов состоит проект, обозначили вилку по цене и точную стоимость за первый этап проекта (создание прототипа сайта). Обычно общение с менеджерами по продажам это боль, но здесь общение проходило с людьми которые непосредственно работают в проектах и могут ответить на любой вопрос сходу, поэтому общаться было просто и приятно.<br/>

    В процессе работы мне запомнились мозговые штурмы, особенно момент, когда придумали интерактивную карту Московской области, чтобы показать, что мы что мы можем доставлять товары из интернет магазинов во все города, поселки, снт, коттеджные поселки в области, соблюдая интервалы доставки 4 часа, даже если они находятся за 150 км. от МКАД. Сама идея была свежая, а впоследствии её вообще скопировали конкуренты (а это лучший индикатор!). Когда прототип сайта был готов, мы получили точную цену на разработку сайта в виде сметы с понятным описанием и таймингом, было видно что сколько времени займет и далее можно было удобно контролировать ход работы.<br/>

    В результате мы получили готовый сайт в аккурат к выставке и он реально помог нам продавать идею доставки товаров из интернет-магазинов в Московскую область (что там живут люди и они тоже покупают).<br/>
    На момент написания отзыва это уже не первый наш совместный проект и я уже рекомендовал команду fresco.agency своим знакомым (коллегам по рынку) как надежного подрядчика и партнера в сфере веб-разработки.`,
  },
  {
    image: require("./img/tompisatel-logo.svg").default,
    name: "Дмитрий Барчук",
    position: "Заместитель руководителя проектов президентских грантов ТООООО «Союз писателей России»",
    site: "tompisatel.ru",
    text: `Получив финансирование на проект от Фонда президентских грантов мы хотели получить неординарный, современный, удобный в поддержке и насыщенный информационно сайт. Проводя мониторинг услуг по разработке сайтов в Томске мы узнали о компании fresco.agency.<br/>

    Дизайнеру удалось легко ухватить суть дизайн-концепции сайта. Большинство предложений по дизайну сразу попадали в точку, исподволь с помощью современных технологий компьютерной графики передавали творческую атмосферу и работу писательской организации.<br/>
    
    Результатом мы, естественно, довольны. Во многом благодаря современному сайту нам удалось выиграть еще несколько президентских грантов. Высокую оценку сайт получил и от наших коллег по творческому цеху в Союзе писателей России в Москве, а также от наших читателей со всего мира.<br/>
    
    Компанию можно охарактеризовать одним словом – «Креатив!».
    `,
  },
  {
    image: require("./img/privodservice-logo.svg").default,
    name: "Юлия Ожован",
    position: "",
    site: "приводсервис.рф",
    text: `До совместной работы с компанией fresco.agency у нас был опыт работы с московской компанией, качество работы которой нас не устроило, сотрудничество стало одним большим разочарованием.<br/>

    Выбирая нового подрядчика на разработку сайта мы специально искали местного подрядчика, чтобы можно было оперативно решать множество возникающих технических вопросов и нюансов, обусловленных спецификой нашего товара и услуг. В пользу выбора компании сыграло адекватное предложение по цене при профессионализме на уровне хороших столичных агентств. В итоге, созданный сайт выглядит так, как мы его изначально задумывали, все детали и мелочи были учтены. Все возникающие вопросы по-ходу оперативно решались руководителем проекта Дмитрием (за что ему большое спасибо!).<br/>

    Бизнес-результат от проекта трудно измерить, но нашим клиентам понятна размещенная на сайте информация, а сам сайт стал неотъемлемой частью нашего бренда и презентует нас как современную и конкурентоспособную компанию. А это значит, что все сделано правильно!
    `,
  },
  {
    image: require("./img/vlf-logo-otzyv.svg").default,
    name: "Михаил Сергейчик",
    position: "Директор НП «Финансы Коммуникации Информация»",
    site: "vlfin.ru",
    text: `
    Мы искали студию, работающую с Битриксом, отобрали несколько кандидатов и оценили их по внутренним для нас критериям. В итоге остановили свой выбор на fresco.agency, к этому нас подтолкнула, в том числе, рекомендация которую дали знакомые знакомых.<br/>
    Наш портал о финансовой грамотности нуждался в редизайне и мы понимали, что впереди нас ждет большая работа, чтобы учесть интересы наших читателей и внедрить все необходимые функции на сайте.<br/>
    В работе с агентством можно выделить оперативность реагирования, учет большинства замечаний от нас и аргументированное объяснение почему часть замечаний была неприменима. С другой стороны - для людей, которые не особо разбираются в “сайтостроительстве” нам было сложно понять необходимость множества этапов и итераций, которые казались нам схожими.<br/>
    Однако, полученным дизайном портала мы остались довольны и собираемся внедрить его в ближайшее время!<br/>
    Рекомендую команду fresco.agency как настоящих знатоков своего дела!
  `,
  },
  {
    image: require("./img/tokko-logo.svg").default,
    name: "Алексей Минлигалеев",
    position: "Управляющий партнер адвокатского бюро «ТОККО»",
    site: "tokko.legal",
    text: `Обратились в fresco.agency по совету дизайнера, с которым работали над брендом. В предложении от компании нам понравились более комфортные условия работы, также устроила цена и мы начали сотрудничество. В процессе запомнился адекватный подход к нашим задачам, а также то, что после запуска сайта для нас подготовили наглядное видео по тому, как управлять нашим сайтом через административную панель. Доволен результатом и сроками на 10 из 10, буду рекомендовать!`,
  },
  {
    image: require("./img/1apart-logo.svg").default,
    name: "Екатерина Писанко",
    position: "Директор ООО «Первый Апарт-отель»",
    site: "1apart.ru",
    text: `Выражаем благодарность веб-студии fresco.agency за высокий профессионализм в своем деле.<br/>

    В компанию обратились еще до открытия, совместно разработали бренд и фирменный стиль, который высоко оценивают наши клиенты. Затем студия разработала нам сайт со стильным и современным дизайном и удобной мобильной версией. Работы были выполнены качественно и в срок. От клиентов часто получаем положительные отзывы о сайте, особенно о мобильной версии.<br/>

    На сегодняшний день веб-студия является нашим незаменимым партнером в продвижении Апарт-отеля посредством интернет-рекламы.<br/>

    Мы очень довольны Вашей работой и надеемся на дальнейшее сотрудничество!`,
  },
  {
    image: require("./img/unisiter-logo.svg").default,
    name: "Николай Истомин",
    position: "Директор IT-компании uniSiter",
    site: "unisiter.ru",
    text: `Заказывали в fresco.agency редизайн главной страницы сайта. Пересекались и раньше, знали о хорошем качестве работ, поэтому особо не выбирали.<br/>

    Результатом и даже самим процессом работы очень довольны. Команда помогла определиться со стилем, конструктивно спорила и доказывала верность предлагаемых решений (что редко встречается) и наглядно представляла результаты каждого этапа работ.<br/>

    Обновления на сайте заметили как наши действующие клиенты, так и потенциальные (заказов звонков и заявок стало больше).<br/>

    fresco.agency однозначно рекомендуем!`,
  },
  {
    image: require("./img/palmir-logo.svg").default,
    name: "Андрей Бугаев",
    position: "Директор ООО «Палмир»",
    site: "",
    text: `Ранее с созданием сайта не сталкивались и вышли на компанию по рекомендации от дизайнеров. Руководитель проекта и специалисты агентства быстро подхватили нашу задачу, учли пожелания и, что запомнилось, внимательно подошли к нюансам проекта. Например, предлагали решения как должен работать калькулятор расчета и что стоит в нём учесть. В целом понравилась активная позиция на всем пути сотрудничества. Рекомендую как профессионалов в своём деле!`,
  },
  {
    image: require("./img/tc-gorod-logo.svg").default,
    name: "Андрей Корнющенко",
    position: "Директор ТЦ «ГОРОД»",
    site: "tcgorod.ru",
    text: `Впервые узнали о компании на сайте бизнес-центра "Красное Знамя". Выбирали из разных студий, остановились в итоге на fresco.agency , так как понравилось полнота, скорость ответа по заявке и бюджет. Из работы запомнились личные встречи, конструктивный обмен идеями и предложениями.
    Остались довольны результатом, получив современный и привлекательный сайт, который отражает все наши пожелания. Единственное, что не устроило это скорость сайта, вероятно, так как сайт создан на основе шаблона. А в остальном было комфортно работать с командой, было заметно, что каждый был заинтересован в достижении положительных результатов.`,
  },
];
