import React, {useEffect, useState} from 'react'
import { Helmet } from 'react-helmet'

import AOS from 'aos'
import 'aos/dist/aos.css'

import Slider from "react-slick"
import {useParams} from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';

import {Form} from "../components/Form"

import {getCaseData, SITE_URL} from '../api'

import { Parallax } from 'react-parallax'

import "slick-carousel/slick/slick.css";
import "bootstrap/dist/css/bootstrap.css"
import "../index.sass"
import "./assets/Case.sass"

import arrowSlider from "../images/arrow-slider.svg"

import iMac from "../images/imac.png"

const Case = () => {

    AOS.init()

    const {id} = useParams()

    const [caseValue, setCaseValue] = useState([]);
    const [BlocksList, setBlocksList] = useState([]);
    const [tagList, setTagList] = useState([]);

    useEffect(() => {
        getCaseData(id).then((data) => setCaseValue(data.object))
    }, [])

    let SEO_DESCR, SEO_KEYWORDS, TITLE = "Проекты. fresco.agency — AI и веб-интегратор"

    if (Object.entries(caseValue).length > 0){
        if (caseValue.SEO_TITLE)
            TITLE = caseValue.SEO_TITLE
        TITLE = caseValue.SEO_TITLE
        SEO_DESCR = caseValue.SEO_DESCR
        SEO_KEYWORDS = caseValue.SEO_KEYWORDS
    }

    if (BlocksList.length === 0) {
        if (caseValue.BlocksList){
            setBlocksList(Object.entries(caseValue.BlocksList))
        }
        if (caseValue.tagList) {
            setTagList(caseValue.tagList.split(', '))
        }
    }

    //SLIDER
    const [currentSlide, setCurrentSlide] = useState(0)
    const handleAfterChange = index => {
        setCurrentSlide(index)
    }

    function NextArrow(props) {
        const { className, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}
            >
                <img src={arrowSlider} alt=""/>
            </div>
        );
    }

    function PrevArrow(props) {
        const { className, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}
            >
                <img src={arrowSlider} alt=""/>
            </div>
        );
    }

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        vertical: true,
        fade: true,
        speed: 300,
        slidesToShow: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        afterChange: handleAfterChange
    }
    //END

    return (
        <>
            <Helmet>
                <title>{ TITLE }</title>
                <meta name="description" content={SEO_DESCR} />
                <meta name="keywords" content={SEO_KEYWORDS} />
            </Helmet>

            <div className="case_page">
                <Parallax className="header" bgImage={SITE_URL + caseValue.bgImage} bgImageSize="100%" strength={100}>
                    <div className="inner">
                        {/*{caseValue.id == "6" && "nice"}*/}
                        <h1 data-aos="fade-up" data-aos-delay="100">{caseValue.headerTitle}</h1>
                        {caseValue.headerLink &&
                            <div className="link" data-aos="fade-up" data-aos-delay="150">
                                <a href={"https://" + caseValue.headerLink + "/"} target="_blank" rel="noreferrer" className="italic_link">{caseValue.headerLink}</a>
                            </div>
                        }

                        {caseValue.headerDeskr &&
                            <div className="description" data-aos="fade-up" data-aos-delay="200">{caseValue.headerDeskr}</div>
                        }
                        <div className="tag_list" data-aos="fade-up" data-aos-delay="250">
                            {
                                tagList.map((item, key) =>
                                    <span key={key}>{item}</span>
                                )
                            }
                        </div>
                    </div>
                </Parallax>

                <>

                    {
                        BlocksList.map(Block => {
                            switch (Block[1].template) {
                                // готово
                                case 'Заголовок слева, справа текст':
                                    return (
                                        <div className={'about ' + (Block[1].pt === "0" ? ' pt-0 ' : '') + (Block[1].pb === "0" ? ' pb-0 ' : '')} style={{backgroundColor: Block[1].bgColor}}>
                                            <div className="container">
                                                <div className="row justify-content-md-center">
                                                    <div className="col-12 col-xl-10">
                                                        <div className="row">
                                                            <div className="col-12 col-md-5">
                                                                <h2 data-aos="fade-in" data-aos-delay="0">{Block[1].title}</h2>
                                                            </div>
                                                            <div className="col-12 col-md-6 offset-md-1">
                                                                <div className="text" data-aos="fade-in" data-aos-delay="100">
                                                                    {ReactHtmlParser(Block[1].text)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                // готово
                                case 'Блок с паралакс изображением':
                                    return (
                                        Block[1].height === "1"
                                            ?
                                            <Parallax className="paralax h-640" blur={0} bgImage={SITE_URL + Block[1].image} strength={-100}></Parallax>
                                            :
                                            <Parallax className="paralax" blur={0} bgImage={SITE_URL + Block[1].image} strength={-100}></Parallax>
                                    )

                                // готово
                                case 'Заголовок слева, справа список с иконками':
                                    return (
                                        <div className={'about ' + (Block[1].pt === "0" ? ' pt-0 ' : '') + (Block[1].pb === "0" ? ' pb-0 ' : '')} style={{backgroundColor: Block[1].bgColor}}>
                                            <div className="container">
                                                <div className="row justify-content-md-center">
                                                    <div className="col-12 col-xl-10">
                                                        <div className="row">
                                                            <div className="col-12 col-md-5">
                                                                <h2 data-aos="fade-in">{Block[1].title}</h2>
                                                            </div>
                                                            <div className="about__textList col-12 col-md-6 offset-md-1">
                                                                {Object.entries(Block[1].targetList).map((target,i) => {
                                                                    if (target[1].text)
                                                                        switch (target[1].image) {
                                                                            case '':
                                                                                return (<div className="text" data-aos="fade-in" key={i}>{ReactHtmlParser(target[1].text)}</div>)
                                                                            default:
                                                                                return (<div className="icon" data-aos="fade-in" key={i}>
                                                                                    <img
                                                                                        src={SITE_URL + target[1].image}
                                                                                        alt=""/>
                                                                                    {ReactHtmlParser(target[1].text)}
                                                                                </div>)
                                                                        }
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                // готово
                                case 'Нумерованный список':
                                    return (
                                        <div className={'targets ' + (Block[1].pt === "0" ? ' pt-0 ' : '') + (Block[1].pb === "0" ? ' pb-0 ' : '')} style={{backgroundColor: Block[1].bgColor}}>
                                            <div className="container">
                                                <div className="row justify-content-md-center">
                                                    <div className="col-12 col-md-10 col-xl-7">
                                                        <div className="title">
                                                            <h2 data-aos="fade-in">{Block[1].title}</h2>
                                                        </div>
                                                        <div className="targetList" data-aos="fade-up" data-aos-delay="100" style={{backgroundColor: Block[1].bgColorContainer}}>
                                                            {Object.entries(Block[1].list).map((target, index) => {
                                                                if(target[1].text)
                                                                    return (<div>
                                                                        <span>{index+1}</span>
                                                                        <p>{target[1].text}</p>
                                                                    </div>)
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                // готово
                                case 'Блок с двумя колонками заголовок + текст':
                                    return (
                                        <div className={'about about--type_3 ' + (Block[1].pt === "0" ? ' pt-0 ' : '') + (Block[1].pb === "0" ? ' pb-0 ' : '')} style={{backgroundColor: Block[1].bgColor}}>
                                            <div className="container">
                                                <div className="row justify-content-md-center">
                                                    <div className="col-12 col-xl-10">
                                                        <div className="row">
                                                            <div className="col-12 col-md-5">
                                                                <div className="title"><h2 data-aos="fade-in">{Block[1].title_1}</h2></div>
                                                                <div className="text" data-aos="fade-in" data-aos-delay="100">
                                                                    {ReactHtmlParser(Block[1].text_1)}
                                                                </div>
                                                            </div>

                                                            <div className="col-12 offset-md-1 col-md-6 mt-5 mt-md-0">
                                                                <div className="title"><h2 data-aos="fade-in">{Block[1].title_2}</h2></div>
                                                                <div className="text" data-aos="fade-in" data-aos-delay="100">
                                                                    {ReactHtmlParser(Block[1].text_2)}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                // готово
                                case '4 колонки с изображениями':
                                    return (
                                        <div className="prototypes" style={{backgroundColor: Block[1].bgColor}}>
                                            <div className="container">
                                                <div className="prototypes_list">
                                                    <div>
                                                        <div data-aos="fade-up"><img src={SITE_URL + Block[1].calls[0].image} alt=""/></div>
                                                        <div data-aos="fade-up"><img src={SITE_URL + Block[1].calls[1].image} alt=""/></div>
                                                        <div data-aos="fade-up"><img src={SITE_URL + Block[1].calls[2].image} alt=""/></div>
                                                    </div>

                                                    <div>
                                                        <div data-aos="fade-up"><img src={SITE_URL + Block[1].calls[3].image} alt=""/></div>
                                                        <div data-aos="fade-up"><img src={SITE_URL + Block[1].calls[4].image} alt=""/></div>
                                                        <div data-aos="fade-up"><img src={SITE_URL + Block[1].calls[5].image} alt=""/></div>
                                                    </div>

                                                    <div>
                                                        <div data-aos="fade-up"><img src={SITE_URL + Block[1].calls[6].image} alt=""/></div>
                                                        <div data-aos="fade-up"><img src={SITE_URL + Block[1].calls[7].image} alt=""/></div>
                                                        <div data-aos="fade-up"><img src={SITE_URL + Block[1].calls[8].image} alt=""/></div>
                                                    </div>

                                                    <div>
                                                        <div data-aos="fade-up"><img src={SITE_URL + Block[1].calls[9].image} alt=""/></div>
                                                        <div data-aos="fade-up"><img src={SITE_URL + Block[1].calls[10].image} alt=""/></div>
                                                        <div data-aos="fade-up"><img src={SITE_URL + Block[1].calls[11].image} alt=""/></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                // готово
                                case 'Скриншот с текстовыми сносками слева':
                                    return (
                                        <div className={'features ' + (Block[1].pt === "0" ? ' pt-0 ' : '') + (Block[1].pb === "0" ? ' pb-0 ' : '')} style={{backgroundColor: Block[1].bgColor}}>
                                            <div className="container">
                                                <div className="row">

                                                    {Object.entries(Block[1].screensDesckrList).map((item) => {
                                                        if(item[1].title)
                                                            return (
                                                                <>
                                                                    <div className="col-5">
                                                                        <div className="item" data-aos="fade-up">
                                                                            <div className="name">
                                                                                <span>{item[1].title}</span>
                                                                            </div>
                                                                            <div className="line">
                                                                                <div></div>
                                                                            </div>
                                                                            <div className="text">
                                                                                <p>{item[1].text}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-7">
                                                                        <div className="image">
                                                                            <img src={SITE_URL + item[1].image} alt=""/>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                    })}

                                                </div>
                                            </div>
                                        </div>
                                    )

                                // готово
                                case '2 изображения 1:1':
                                    return (
                                        <div className="images_1-1" style={{backgroundColor: Block[1].bgColor}}>
                                            <div className="container">
                                                <div className="images">
                                                    <div className="img">
                                                        <div className="mobile">
                                                            <Parallax bgImage={SITE_URL + Block[1].image_1} bgImageSize="100%" strength={-120}></Parallax>
                                                        </div>
                                                        <div className="pc">
                                                            <Parallax bgImage={SITE_URL + Block[1].image_1} bgImageSize="100%" strength={-420}></Parallax>
                                                        </div>
                                                    </div>
                                                    <div className="img">
                                                        <div className="mobile">
                                                            <Parallax bgImage={SITE_URL + Block[1].image_2} bgImageSize="100%" strength={120}></Parallax>
                                                        </div>
                                                        <div className="pc">
                                                            <Parallax bgImage={SITE_URL + Block[1].image_2} bgImageSize="100%" strength={420}></Parallax>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                // готово
                                case '3 паралакс скришота':
                                    return (
                                        <div className="screens_3" style={{backgroundColor: Block[1].bgColor}}>
                                            <div className="container">
                                                <div className="screens_list">
                                                    <div className="screen screen-1" data-aos="fade-up">
                                                        <div className="img">
                                                            <img src={SITE_URL + Block[1].image_1} alt=""/>
                                                        </div>
                                                    </div>
                                                    <div className="screen screen-2" data-aos="fade-down">
                                                        <div className="img">
                                                            <img src={SITE_URL + Block[1].image_2} alt=""/>
                                                        </div>
                                                    </div>
                                                    <div className="screen screen-3" data-aos="fade-in">
                                                        <div className="img">
                                                            <video src={SITE_URL + Block[1].image_3} muted="muted" autoPlay="autoplay" loop="loop" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                // готово
                                case '2 скриншота - правый паралакс':
                                    return (
                                        <div className="screens_1-2" style={{backgroundColor: Block[1].bgColor}}>
                                            <div className="container">
                                                <div className="screens_list">
                                                    <div className="screen screen-1" data-aos="fade-in">
                                                        <div className="img">
                                                            <Parallax bgImage={SITE_URL + Block[1].image_1} bgImageSize="100%" strength={0}></Parallax>
                                                        </div>
                                                    </div>
                                                    <div className="screen screen-2" data-aos="fade-in" data-aos-delay="100">
                                                        <div className="img">
                                                            <Parallax bgImage={SITE_URL + Block[1].image_2} bgImageSize="100%" strength={50}></Parallax>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                // готово
                                case 'Сетка скриншотов':
                                    return (
                                        <div className="screens_greed" style={{backgroundColor: Block[1].bgColor}}>
                                            <Parallax blur={0} bgImage={SITE_URL + Block[1].image} strength={-100}></Parallax>
                                        </div>
                                    )

                                // готово
                                case 'Стилизованный текст и слайдер (независимый)':
                                    return (
                                        <div className={'about text_and_slider ' + (Block[1].pt === "0" ? ' pt-0 ' : '') + (Block[1].pb === "0" ? ' pb-0 ' : '')} style={{backgroundColor: Block[1].bgColor}}>
                                            <div className="container">
                                                <div className="row justify-content-md-center">
                                                    <div className="col-12 col-xl-12">
                                                        <div className="row">
                                                            <div className="col-12 col-md-5 text_block">
                                                                <div className="verticalCenter">
                                                                    <div>
                                                                        <div className="text" data-aos="fade-in">
                                                                            <div className="title"><h2>{Block[1].title}</h2></div>
                                                                            {ReactHtmlParser(Block[1].text)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 offset-md-1 slider_block">
                                                                <div className="verticalCenter">
                                                                    <div>
                                                                        <div className="slider_under_num onlyScreen">
                                                                            <Slider {...settings}>
                                                                                {Object.entries(Block[1].slides).map(element => {
                                                                                    if (element[1].image)
                                                                                        return(<div className="slide"><img src={SITE_URL + element[1].image} alt=""/></div>)
                                                                                })}
                                                                            </Slider>
                                                                            <div className="counterSlides">
                                                                                <span className="currentSlide">{currentSlide + 1}</span><span className="countSlides">/{Object.entries(Block[1].slides).length - 1}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                // готово
                                case 'Слева текст - справа слайдер со всплывающими поверх изображениями':
                                    return (
                                        <div className={'about text_and_slider ' + (Block[1].pt === "0" ? ' pt-0 ' : '') + (Block[1].pb === "0" ? ' pb-0 ' : '')} style={{backgroundColor: Block[1].bgColor}}>
                                            <div className="container">
                                                <div className="row justify-content-md-center">
                                                    <div className="col-12 col-xl-12">
                                                        <div className="row">
                                                            <div className="col-12 col-md-5 text_block">
                                                                <div className="verticalCenter">
                                                                    <div>
                                                                        <div className="text" data-aos="fade-in">
                                                                            <div className="title"><h2>{Block[1].title}</h2></div>
                                                                            {ReactHtmlParser(Block[1].text)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-6 offset-md-1 slider_block">
                                                                <div className="verticalCenter">
                                                                    <div>
                                                                        <div className="slider_under_num">
                                                                            <Slider {...settings}>
                                                                                {Object.entries(Block[1].images).map(element => {
                                                                                    if (element[1].image)
                                                                                        return(
                                                                                            <div key={element.id} className="slide">
                                                                                                <img src={SITE_URL + element[1].image} alt=""/>
                                                                                                {Object.entries(element[1].images).map(element2 => {
                                                                                                    if (element2[1].image)
                                                                                                        return(
                                                                                                            <img src={SITE_URL + element2[1].image} alt="" data-aos="fade-in" data-aos-delay="100" className={element2[1].horizontal + " " + element2[1].vertical}/>
                                                                                                        )
                                                                                                })}
                                                                                            </div>)
                                                                                })}
                                                                            </Slider>
                                                                            <div className="counterSlides">
                                                                                <span className="currentSlide">{currentSlide + 1}</span><span className="countSlides">/{Object.entries(Block[1].images).length - 1}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                // готово
                                case 'Справа текст - слева слайдер со всплывающими поверх изображениями':
                                    return (
                                        <div className={'about text_and_slider ' + (Block[1].pt === "0" ? ' pt-0 ' : '') + (Block[1].pb === "0" ? ' pb-0 ' : '')} style={{backgroundColor: Block[1].bgColor}}>
                                            <div className="container">
                                                <div className="row justify-content-md-center">
                                                    <div className="col-12 col-xl-12">
                                                        <div className="row">
                                                            <div className="col-12 col-md-6 slider_block">
                                                                <div className="verticalCenter">
                                                                    <div>
                                                                        <div className="slider_under_num">
                                                                            <Slider {...settings}>
                                                                                {Object.entries(Block[1].images).map(element => {
                                                                                    if (element[1].image)
                                                                                        return(
                                                                                            <div key={element.id} className="slide">
                                                                                                <img src={SITE_URL + element[1].image} alt=""/>
                                                                                                {Object.entries(element[1].images).map(element2 => {
                                                                                                    if (element2[1].image)
                                                                                                        return(
                                                                                                            <img src={SITE_URL + element2[1].image} alt="" data-aos="fade-in" data-aos-delay="100" className={element2[1].horizontal + " " + element2[1].vertical}/>
                                                                                                        )
                                                                                                })}
                                                                                            </div>)
                                                                                })}
                                                                            </Slider>
                                                                            <div className="counterSlides">
                                                                                <span className="currentSlide">{currentSlide + 1}</span><span className="countSlides">/{Object.entries(Block[1].images).length - 1}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-5 offset-md-1 text_block">
                                                                <div className="verticalCenter">
                                                                    <div>
                                                                        <div className="text" data-aos="fade-in">
                                                                            <div className="title"><h2>{Block[1].title}</h2></div>
                                                                            {ReactHtmlParser(Block[1].text)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                case '3 колонки с изображениями':
                                    return(
                                        <div className="col-3-images" style={{backgroundColor: Block[1].bgColor}}>
                                            <div className="container">
                                                <div className="images_list">
                                                    <div>
                                                        <div data-aos="fade-up"><img src={SITE_URL + Block[1].calls[0].image} alt=""/></div>
                                                        <div data-aos="fade-up"><img src={SITE_URL + Block[1].calls[1].image} alt=""/></div>
                                                        <div data-aos="fade-up"><img src={SITE_URL + Block[1].calls[2].image} alt=""/></div>
                                                    </div>

                                                    <div>
                                                        <div data-aos="fade-up"><img src={SITE_URL + Block[1].calls[3].image} alt=""/></div>
                                                        <div data-aos="fade-up"><img src={SITE_URL + Block[1].calls[4].image} alt=""/></div>
                                                        <div data-aos="fade-up"><img src={SITE_URL + Block[1].calls[5].image} alt=""/></div>
                                                    </div>

                                                    <div>
                                                        <div data-aos="fade-up"><img src={SITE_URL + Block[1].calls[6].image} alt=""/></div>
                                                        <div data-aos="fade-up"><img src={SITE_URL + Block[1].calls[7].image} alt=""/></div>
                                                        <div data-aos="fade-up"><img src={SITE_URL + Block[1].calls[8].image} alt=""/></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                // готово
                                case 'Текст + 4 колонки изображений':
                                    return (
                                        <>
                                            <div className={'about ' + (Block[1].pt === "0" ? ' pt-0 ' : '')} style={{backgroundColor: Block[1].bgColor}}>
                                                <div className="container">
                                                    <div className="row justify-content-md-center" data-aos="fade-in">
                                                        <div className="col-12 col-md-8 col-lg-6">
                                                            <h2>{Block[1].title}</h2>
                                                            {ReactHtmlParser(Block[1].text)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="prototypes offset" style={{backgroundColor: Block[1].bgColor}}>
                                                <div className="fade" style={{backgroundImage: "linear-gradient(to bottom, rgba(223, 220, 240, 0)," +  Block[1].bgColor + ")"}}></div>
                                                <div className="container">
                                                    <div className="prototypes_list">
                                                        <div>
                                                            <div data-aos="fade-up"><img src={SITE_URL + Block[1].image[0].image} alt=""/></div>
                                                            <div data-aos="fade-up"><img src={SITE_URL + Block[1].image[1].image} alt=""/></div>
                                                            <div data-aos="fade-up"><img src={SITE_URL + Block[1].image[2].image} alt=""/></div>
                                                        </div>

                                                        <div>
                                                            <div data-aos="fade-up"><img src={SITE_URL + Block[1].image[3].image} alt=""/></div>
                                                            <div data-aos="fade-up"><img src={SITE_URL + Block[1].image[4].image} alt=""/></div>
                                                            <div data-aos="fade-up"><img src={SITE_URL + Block[1].image[5].image} alt=""/></div>
                                                        </div>

                                                        <div>
                                                            <div data-aos="fade-up"><img src={SITE_URL + Block[1].image[6].image} alt=""/></div>
                                                            <div data-aos="fade-up"><img src={SITE_URL + Block[1].image[7].image} alt=""/></div>
                                                            <div data-aos="fade-up"><img src={SITE_URL + Block[1].image[8].image} alt=""/></div>
                                                        </div>

                                                        <div>
                                                            <div data-aos="fade-up"><img src={SITE_URL + Block[1].image[9].image} alt=""/></div>
                                                            <div data-aos="fade-up"><img src={SITE_URL + Block[1].image[10].image} alt=""/></div>
                                                            <div data-aos="fade-up"><img src={SITE_URL + Block[1].image[11].image} alt=""/></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )

                                // готово
                                case 'Большой заголовок':
                                    return (
                                        <div className={'about ' + (Block[1].pt === "0" ? ' pt-0 ' : '') + (Block[1].pb === "0" ? ' pb-0 ' : '')} style={{backgroundColor: Block[1].bgColor}}>
                                            <div className="container">
                                                <div className="row justify-content-md-center text-center">
                                                    <div className="col-12 col-md-8 col-lg-6">
                                                        <h2 data-aos="fade-in">{Block[1].title}</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                // готово
                                case 'Заголовок + текст + слайдер во всю ширину':
                                    return (
                                        <>
                                            <div className={'about ' + (Block[1].pt === "0" ? ' pt-0 ' : '') + (Block[1].pb === "0" ? ' pb-0 ' : '')} style={{backgroundColor: Block[1].bgColor}}>
                                                <div className="container">
                                                    <div className="row justify-content-md-center">
                                                        <div className="col-12 col-md-8 col-lg-6" data-aos="fade-in" data-aos-delay="100">
                                                            <h3>{Block[1].title}</h3>
                                                            {ReactHtmlParser(Block[1].text)}
                                                        </div>

                                                        <div className="col-12 col-xl-10">
                                                            <div className="slider_under_num bigSlider onlyScreen">
                                                                {Object.values(Block[1].images).length > 1 ?
                                                                    <>
                                                                        <Slider {...settings}>
                                                                            {Object.entries(Block[1].images).map(element => {
                                                                                if (element[1].image)
                                                                                    return(
                                                                                        <div key={element.id} className="slide">
                                                                                            <img src={SITE_URL + element[1].image} alt=""/>
                                                                                        </div>)
                                                                            })}
                                                                        </Slider>
                                                                        <div className="counterSlides">
                                                                            <span className="currentSlide">{currentSlide + 1}</span><span className="countSlides">/{Object.entries(Block[1].images).length - 1}</span>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <div className="bigScreen pt-0 pb-0">
                                                                        <div className="container">
                                                                            <div className="row justify-content-md-center">
                                                                                <div className="col-12">
                                                                                    <img src={SITE_URL + Block[1].images[0].image} alt=""/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )

                                case 'Заголовок + текст + слайдер (табы) во всю ширину':
                                  const listImages = Object.values(Block[1].images);
                                  const linksImages = listImages.map((x) => x.image);
                                  const slides = linksImages.filter((x) => x !== undefined);
                                  return (
                                      <>
                                          <div className={'about ' + (Block[1].pt === "0" ? ' pt-0 ' : '') + (Block[1].pb === "0" ? ' pb-0 ' : '')} style={{backgroundColor: Block[1].bgColor}}>
                                              <div className="container">
                                                  <div className="row justify-content-md-center">
                                                      <div className="col-12 col-md-8 col-lg-6" data-aos="fade-in" data-aos-delay="100">
                                                          <h3>{Block[1].title}</h3>
                                                          {ReactHtmlParser(Block[1].text)}
                                                      </div>

                                                      <div className="col-12 col-xl-10">
                                                          <div className="slider_under_num bigSlider onlyScreen">
                                                              {Object.values(Block[1].images).length > 1 ? 
                                                                  <>                                                                 
                                                                      <Slider {...settings} 
                                                                          className='slider-tabs'
                                                                          arrows={false}
                                                                          dots={true}
                                                                          appendDots={ (dots) => <div><ul> {dots} </ul></div> }
                                                                      >
                                                                          {Object.entries(Block[1].images).map(element => {
                                                                              if (element[1].image)
                                                                                  return(
                                                                                      <div key={element.id} className="slide">
                                                                                          <img src={SITE_URL + element[1].image} alt=""/>
                                                                                      </div>)
                                                                          })}
                                                                      </Slider>
                                                                  </>
                                                                  :
                                                                  <div className="bigScreen pt-0 pb-0">
                                                                      <div className="container">
                                                                          <div className="row justify-content-md-center">
                                                                              <div className="col-12">
                                                                                  <img src={SITE_URL + Block[1].images[0].image} alt=""/>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              }
                                                          </div>
                                                      </div>

                                                  </div>
                                              </div>
                                          </div>
                                      </>
                                  )

                                // готово
                                case 'Прокрутка скриншота внутри экрана':
                                    return (
                                        <div className={'scrollScreen ' + (Block[1].pt === "0" ? ' pt-0 ' : '') + (Block[1].pb === "0" ? ' pb-0 ' : '')} style={{backgroundColor: Block[1].bgColor}}  data-aos="fade-in">
                                            <div className="container">
                                                <div className="row justify-content-md-center">
                                                    <div className="col-12 col-xl-10">
                                                        <div className="wrapperScreen">
                                                            <img src={iMac} alt=""/>
                                                            <div className="scrollImage">
                                                                <img src={SITE_URL + Block[1].image} alt=""/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                // готово
                                case 'Скриншоты разных разрешений':
                                    return (
                                        <div className={'about ' + (Block[1].pt === "0" ? ' pt-0 ' : '') + (Block[1].pb === "0" ? ' pb-0 ' : '')} style={{backgroundColor: Block[1].bgColor}}>
                                            <div className="container">
                                                <div className="row justify-content-md-center">
                                                    <div className="col-12">
                                                        <img src={SITE_URL + Block[1].image} alt="" style={{display: "block", width: "100%"}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                case 'Блок с видео по-центру':
                                    return (
                                        <div className={'about ' + (Block[1].fullScreen === "1" ? ' fullScreen' : '')} style={{backgroundColor: Block[1].bgColor}}>
                                            <div className="container">
                                                <div className="row justify-content-md-center">
                                                    <div className="col-12">
                                                        <div className="video">
                                                            <video src={SITE_URL + Block[1].video} muted="muted" autoPlay="autoplay" loop="loop" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                // готово
                                case 'Отзыв':
                                    return (
                                        <div className="review" data-aos="fade-in" style={{backgroundColor: Block[1].bgColor}}>
                                            <div className="container">
                                                <div className="row justify-content-md-center">
                                                    <div className="col-12 col-xl-10">
                                                        <div className="reviewWrapper">
                                                            <div className="ava">
                                                                <div style={{backgroundImage: "url("+ SITE_URL + Block[1].ava +")"}}>
                                                                    <span>,,</span>
                                                                </div>
                                                            </div>
                                                            <div className="text">
                                                                <h2>{Block[1].title}</h2>

                                                                <div className="name">
                                                                    <span><b>{Block[1].name}</b></span>
                                                                    <span>{Block[1].role}, <a href={'http://' + Block[1].site} target="_blank" rel="noreferrer" >{Block[1].site}</a></span>
                                                                </div>

                                                                {ReactHtmlParser(Block[1].text)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                default:
                                    return false;
                            }
                        })
                    }
                </>


            </div>

            <section id="footer_form">
                <Form />
            </section>
        </>
    )
}

export {Case}