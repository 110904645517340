import 'bootstrap/dist/css/bootstrap.css'
import "../index.sass";

import AOS from 'aos';
import 'aos/dist/aos.css';

// import "./assets/Page404.sass"

import React from "react";

import img404 from "../images/404.png";
import img404x2 from "../images/404@2x.png";
import img404x3 from "../images/404@3x.png";

const Page404 = () => {

    AOS.init()

    return(
        <div className="page404">
            <div className="container">
                <img srcset={img404 + ' 1x,' + img404x2 + ' 2x,' + img404x3 + ' 3x'} src={img404} data-aos="fade-up" alt="demo"/>
                <p data-aos="fade-up" data-aos-delay="100">
                    <span>Страница не найдена или перемещена.</span>
                    <span>Вместо этого взгляните на наши <a href="/projects" className="italic_link">проекты</a> или перейдите на <a href="/" className="italic_link">главную</a>.</span>
                </p>
            </div>
        </div>
    )

}

export {Page404}