const SITE_URL = 'https://api.fresco.agency/';
const API_URL = SITE_URL + 'api/';

const getMain = async () => {
    const response = await fetch(API_URL + `main`)
    return await response.json();
}

const getAgency = async () => {
    const response = await fetch(API_URL + `agency`)
    return await response.json();
}

const getContacts = async () => {
    const response = await fetch(API_URL + `contacts`)
    return await response.json();
}

const getServices = async () => {
    const response = await fetch(API_URL + `services`)
    return await response.json();
}

const getCases = async () => {
    const response = await fetch(API_URL + `cases`)
    return await response.json();
}

const getCaseData = async (caseId) => {
    const response = await fetch(API_URL +`cases/${caseId}`)
    return await response.json()
}

export {getMain, getAgency, getContacts, getServices, getCases, getCaseData, SITE_URL}